/* @import "~swiper/swiper-bundle.min.css"; */
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* contact us page  */
.mainContactUs {
  width: 100%;
  margin-inline: auto;
  background-image: url("../public/bigDark.jpg");

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 100vh;
}
.secondMain {
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  padding: 70px;
  align-items: center;
}
@media only screen and (max-width: 600px) {
  .secondMain {
    padding: 20px;
    margin-top: 30px;
    margin-bottom: 20px;
  }
  .secondMain p {
    font-size: medium;
  }
}
.headingContactUs {
  padding: 30px 0;
}

/* footer */

.footerContact {
  list-style: none;
  padding-left: 0;
  font-size: 12px;
  margin-bottom: 20px;
}
.footerContact li {
  padding: 3px;
}

.footerContact li a:hover {
  color: cornflowerblue;
}

.footerContact li a {
  text-decoration: none;
  color: white;
  cursor: pointer;
}

/* header */

@media only screen and (max-width: 400px) {
  .headerToolbar img {
    height: 50px;
    object-fit: cover;
  }
  .headerToolbar a {
    font-size: x-small;
  }
}
@media only screen and (min-width: 400px) and (max-width: 520px) {
  .headerToolbar img {
    height: 50px;
    object-fit: cover;
  }
  .headerToolbar a {
    font-size: small;
  }
}

/* Loading Overlay */
.loading-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(60, 60, 60, 0.7); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above the form */
  pointer-events: none; /* Prevent clicks on underlying elements */
}
